import { SpaceCategory, PriceUnit, CapacityCategory, Period } from './../types';

export default {
	apply: 'Verwenden',
	welcome: 'Willkommen bei Venuesuite',
	persons: 'Person',
	persons_plural: 'Personen',
	eventType: 'Art der Veranstaltung',
	personsAmount: ' Anzahl der Teilnehmer',
	date: 'Datum',
	startTime: 'Startzeit',
	endTime: 'Endzeit',
	company: 'Unternehmen',
	time_slots: {
		morning: 'Morgen',
		afternoon: 'Mittag',
		full_day: 'Tag',
	},
	per_person: 'pro Person',
	tax: 'Gesamtpreis Brutto',
	excl_tax: 'Netto',
	heading_subtitle: '{{count}} Personen · {{startDate, MMM D}} · {{startDate, HH:mm}}-{{endDate, HH:mm}}',
	heading_subtitle_plural: '{{count}} Personen · {{startDate, MMM D}} · {{startDate, HH:mm}}-{{endDate, HH:mm}}',
	meeting_type: {
		[SpaceCategory.Meeting]: 'Meeting',
		[SpaceCategory.Training]: 'Training',
		[SpaceCategory.Workshop]: 'Workshop',
		[SpaceCategory.Event]: 'Event',
		[SpaceCategory.Dinner]: 'Abendessen',
	},
	price_unit: {
		[PriceUnit.PerPerson]: '/ Person',
		[PriceUnit.PerDayPart]: '/ Tagesabschnitt',
		[PriceUnit.PerHourPerPerson]: '/ Stunde (p.p.)',
		[PriceUnit.Total]: '/ Gesamtpreis',
	},
	price_period: {
		[Period.DayPart]: '/ Tagesabschnitt',
		[Period.Day]: '/ Tag',
		[Period.Hour]: '/ Stunde',
		[Period.Package]: '/ Person',
	},
	extraCategory: {
		equipment: 'Equipment',
		food: 'Speisen',
		beverages: 'Getränke',
	},
	extrasTitle: 'Extras auf Anfrage',
	extrasDescription:
		'Sie können folgende Produkte jederzeit online anfragen, nachdem Sie Ihre Reservierung getätigt haben.',
	free: 'Kostenlos',
	spaces: 'Raum auswählen',
	including: 'Inklusive - {{equipment}}',
	capacity: '{{min}} - {{max}} Personen',
	comments_questions: 'Kommentare & Fragen (optional)',
	email: 'E-mail',
	email_help: 'Die bestätigungs E-mail wird an diese Adresse gesendet',
	reservation_explanation: `1. 
Wir werden Ihre Anfrage so schnell wie möglich als optionale Reservierung bestätigen (meistens innerhalb weniger Stunden).

2. 
Über E-mail erhalten Sie Zugang zu Ihrer optionalen Reservierung. Mithilfe der Chat Funktion stehen Sie jederzeit mit uns in direktem Kontakt um Rückfragen zu klären oder Anpassungen Ihrer Reservierung vorzunehmen.

3.
Mit einem Klick können Sie Ihre Reservierung definitiv bestätigen oder kostenfrei stornieren.`,
	book: 'Reservierung anfragen',
	bookNow: 'Online Reservierung',
	priceAvailability: 'Verfügbare Räume anzeigen',
	reserve: 'Gesamtpreis anzeigen {{price}}',
	space_included: 'Raummiete Inklusive',
	onlySpace: 'Raum + Extras auf Anfrage',
	onlySpaceDescription: 'Catering und Austattung können jederzeit nach der Reservierung angefragt werden.',
	packages: 'Pauschalen',
	catering: 'Catering',
	equipment: 'Equipment',
	packagesUnavailable: 'Bedauerlicherweise ist keine Pauschale für diesen Raum verfügbar',
	cateringUnavailable: 'Bedauerlicherweise ist kein Catering für diesen Raum verfügbar',
	equipmentUnavailable: 'Bedauerlicherweise ist keine Austattung für diesen Raum verfügbar.',
	find_spaces: 'Passende Packages werden gesucht',
	find_packages: 'Verfügbare Räume werden gesucht',
	show_spaces: 'Verfügbare Räume anzeigen',
	aboutSpace: 'Veranstaltungsraum',
	reservationType: 'Ausgewählte Tagungspauschale',
	summary: 'Ihr {{spaceType}}',
	terms: 'Was passiert wenn ich auf "Reservierung Anfragen" klicke?',
	errors: {
		validation: {
			persons: 'Bitte geben Sie eine Personenzahl ein',
			required: 'Dieses Feld ist Verpflichtend',
			email: 'Bitte geben Sie eine gültige Email Adresse ein',
			name: 'Bitte geben Sie Ihren vollständigen Namen ein',
			company: 'Bitte geben Sie Ihrem Unternehmen ein',
			telephone: 'Bitte geben Sie eine gültige Telefonnummer ein',
			time: 'Die Start Zeit muss vor der Endzeit liegen.',
			after24: 'Die Start Zeit muss mindestens 24 Stunden in der Zukunft liegen.',
		},
		network: {
			fetch_spaces:
				'Hier ist etwas schief gelaufen während wir versucht haben Ihre Reservierung zu erstellen. Bitte versuchen Sie es erneut.',
			fetch_space:
				'Hier ist etwas schief gelaufen während wir versucht haben Ihre Reservierung zu erstellen. Bitte versuchen Sie es erneut.',
			fetch_invoice:
				'Hier ist etwas schief gelaufen während wir versucht haben Ihre Reservierung zu erstellen. Bitte versuchen Sie es erneut.',
			book:
				'Hier ist etwas schief gelaufen während wir versucht haben Ihre Reservierung zu erstellen. Bitte versuchen Sie es erneut.',
			space:
				'Hier ist etwas schief gelaufen während wir versucht haben Ihre Reservierung zu erstellen. Bitte versuchen Sie es erneut.',
		},
	},
	success: {
		title: 'Vielen Dank für Ihre Anfrage.',
		description:
			'Wir haben Ihnen eine Bestätigungs Email an <1>{{email}}</1> gesendet um Ihren Account zu aktivieren.',
		checkmark: [
			'Passen Sie Ihre Buchung jederzeit an',
			'Kontaktieren Sie jederzeit Ihren Account Manager',
			'Bleiben Sie über Änderungen informiert',
		],
		check_spam: 'Sie haben keine E-mail erhalten? Dann kontrollieren Sie bitte Ihren Spam Ordner.',
	},
	done: 'Erledigt',
	time_range: '{{startDate, HH:mm}} bis {{endDate, HH:mm}}',
	noSpaces: 'Wir können leider keine Räume finden, die Ihrer Suchanfrage entsprechen.',
	noSpacesClick: 'Bitte klicken Sie hier um die Kriterien zu ändern.',
	subtotal: 'Gesamtpreis Netto',
	vat: 'MwSt. {{percentage}}%',
	total: 'Gesamtpreis Brutto',
	accomodation_type: {
		double: 'Doppelzimmer',
		single: 'Einzelzimmer',
	},
	many_accomodations: 'Mehr als 20',
	accomodation_subtitle: 'Hotelzimmer',
	accomodation_text: 'Folgende Zimmer stehen für Übernachtungen zur Verfügung',
	equipmentOptions: {
		TV_SCREEN: 'Fernseher',
		PROJECTOR: 'Beamer',
		PROJECTION_AND_SCREEN: 'Beamer & Leinwand',
		MONITOR: 'Bildschirm',
		BEAMER: 'Beamer',
		SPEAKERS: 'Lautsprecher',
		SOUND_SYSTEM: 'Sound System',
		MICROPHONE: 'Mikrofon',
		LASER_POINTER: 'Laser Pointer',
		AUDIO_ENGINEER: 'Audio Techniker',
		LAPTOP: 'Laptop',
		EXTRA_CHAIRS: 'Extra Stühle',
		EXTRA_TABLES: 'Extra Tische',
		LIGHTING: 'Lichtsteuerung',
		SPEAKERS_DESK: 'Rednerpult',
		WARDROBE: 'Garderobe',
		VIDEO_CAMERA: 'Video Kamera',
		CONFERENCE_PHONE: 'Konferenztelefon',
		PENS_AND_PAPER: 'Schreibmaterial',
		WHITEBOARD: 'Whiteboard',
		FLIPCHART: 'Flipchart',
		HEADSET: 'Headset',
		STAGE: 'Bühne',
		DAYLIGHT: 'Tageslicht',
	},
	setupOptions: {
		[CapacityCategory.Cabaret]: 'Kabarett',
		[CapacityCategory.Carre]: 'Carré',
		[CapacityCategory.Circle]: 'Stuhlkreis',
		[CapacityCategory.Exam]: 'Prüfung',
		[CapacityCategory.School]: 'Schule',
		[CapacityCategory.Theater]: 'Theater',
		[CapacityCategory['U-Shape']]: 'U-Form',
		u_shape: 'U-Form',
		[CapacityCategory.Standing]: 'Party',
		[CapacityCategory.Party]: 'Party',
		[CapacityCategory.Dinner]: 'Abendessen',
		[CapacityCategory.Boardroom]: 'Sitzungssaal',
	},
	features: {
		lunch: 'Lunch',
		softdrinks: 'Softdrinks',
		alcoholic: 'Alcohol',
		water: 'Water',
		coffee_and_tea: 'Coffee and tea',
		dinner: 'Dinner',
		snacks: 'Sancks',
	},
	setupTitle: 'Kapazitäten',
	full_name: 'Name',
	telephone: 'Telefonnummer',
	contact_details: 'Kontaktdetails',
	contact_details_help: 'So bleiben wir stets in direktem Kontakt mit Ihnen.',
	loading: 'Wird geladen',
};
